import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Suspense, lazy } from 'react';
//!lazy: solo funciona con componentes funcionales, no class. el componente solo se carga cuando sea necesario, sirve para la optimizacion
//! suspense: muestra el estado de carga hasta que lazy haya montado todo el componente
import axios from 'axios';
import Cookies from 'universal-cookie';
import './components/style/App.css'
import './components/style/styles.css'
import Swal from 'sweetalert2';
// import 'mdb-react-ui-kit/dist/css/mdb.min.css'; //! afecta todo, los botones y los colores
const Header = lazy(() => import('./components/Header'))
const Footer = lazy(() => import('./components/Footer'))
const PlantillaByUser = lazy(() => import('./components/PlantillaByUser'))
const PlanDeAhorro = lazy(() => import('./components/Plan_DeAhorro'))
const PageResetPassword = lazy(() => import('./components/PageResetPassword'))
const Politics_and_privacy = lazy(() => import('./components/politics_and_privacy'))
const Loading = lazy(() => import('./components/Loading'))
const Iniciar_sesion = lazy(() => import('./components/IniciarSesion'))
const PrivacyNotice = lazy(() => import('./components/PrivacyNote'))
const AlertaInicial = lazy(()=>import('./components/AlertaInicial'))
const cookies = new Cookies();
function App() {
  const tokenUser = cookies.get('tokenUser')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(true);
  const [plantillaAGenerar, setPlantillaAGenerar] = useState([])
  const [idUser, setIdByUserLogged] = useState('')

  const consumeIdByUser = async () => {
    try {
      // const res = await axios.post('http://localhost:2024/api/getIdByUser', {tokenUser})
      const res = await axios.post('https://planes-de-ahorro-server.vercel.app/api/getIdByUser', {tokenUser})
      setIdByUserLogged(res.data)

    } catch (error) {
      console.error(`error: ${error}`)
      setLoading(false)
      if (error.response.data.message === 'Acceso no autorizado. Token JWT malformado.') {
        cookies.remove('tokenUser', { path: "/" });
        Swal.fire({
          title: `${error.response.data.message}`,
          position: "center",
          icon: 'error',
        })
        return window.location.replace('https://www.planesdeahorros.online/')
      }
    }
  };

  const getPlantillaByUserLogged = async () => {
    try {
      const datas = {
        idUser,
         tokenUser 
      }
      // const res = await axios.post('http://localhost:2024/api/getPlantillaByUser', datas)
      const res = await axios.post('https://planes-de-ahorro-server.vercel.app/api/getPlantillaByUser', datas)

      if (res.data.message) {
        setMessage(res.data.message)
      } else if (res.data) {
        setPlantillaAGenerar(res.data)
      }

    } catch (error) {
      console.error(`error: ${error}`)
    }
  };

  useEffect(() => {

    if (idUser) {
      // console.log("consume plantilla by user")
      getPlantillaByUserLogged()
      setLoading(false)
    } else if (!tokenUser) setLoading(false)
    else if (tokenUser) consumeIdByUser()

  }, [tokenUser, idUser]);

  if (loading) return <Loading />

  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Header idUser={idUser} tokenUser={tokenUser} />
        <Routes>
          <Route index path='/' element={<PlanDeAhorro idUser={idUser} tokenUser={tokenUser} />} />
          <Route path='/iniciar_sesion' element={<Iniciar_sesion setLoading={setLoading} />} />
          <Route path='/politics_and_privacy' element={<Politics_and_privacy />} />
          <Route path='/new_password/:IdCliente' element={<PageResetPassword />} />
          <Route path='/mi_plantilla' element={<PlantillaByUser plantillaAGenerar={plantillaAGenerar} tokenUser={tokenUser} message={message} idUser={idUser} setLoading={setLoading} />} />
        </Routes>
        <AlertaInicial/>
        <PrivacyNotice />
        <Footer />
      </Suspense>
    </Router>
  );
}

export default App;
